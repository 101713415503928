<template>
  <VisualHero
    :gradient="Boolean(city)"
    :image="image"
    background-color="bg-green-sell"
  >
    <template slot="title">
      Vendere casa{{ city ? ` a ${city.name}` : '' }}, il primo passo per
      <br aria-hidden="true" />un nuovo inizio.
    </template>
    <template slot="description">
      Valuta la tua casa online e scegli la consulenza di UniCredit RE Services
      per dare il giusto valore al tuo immobile.
    </template>
  </VisualHero>
</template>

<script>
// Components
import VisualHero from '~/components/VisualHero.vue'

export default {
  components: {
    VisualHero,
  },

  props: {
    city: {
      type: Object,
      default: null,
    },
  },

  computed: {
    image() {
      const { city } = this

      return city?.img
        ? city.img
        : '/assets/img/visual/ucrs-visual-vendo-new.jpg'
    },

    gradient() {
      return this.city
        ? 'linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.25) 100%)'
        : false
    },
  },
}
</script>
